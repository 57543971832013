<template>
  <div :class="['price', classComputed]">
    <div class="price__current">
      <span class="price__current-value">{{ val }}</span>
      <span
        v-if="!isNaN(val as number)"
        class="price__current-currency"
      >
        ₴
      </span>
    </div>

    <div
      v-if="valOld"
      class="price__old"
    >
      <span class="price__old-value">{{ valOld }}</span>
      <span class="price__old-currency">₴</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
interface IProps {
  val?: number | string;
  valOld?: number;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  hasInStock?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  val: undefined,
  valOld: undefined,
  size: 'md',
});

const classComputed = computed(() => {
  return [
    {
      'price--discount': props.valOld,
      'price--gray': !props.hasInStock,
    },
    props.size && `price--${props.size}`,
  ];
});
</script>

<style lang="scss" src="./Price.scss" />
